<template>
  <div class="wrapper relative">
    <div class="container login-container">
      <div class="login-block">

        <el-form v-if="authAction === 'login'" :label-position="'top'"
                 label-width="100px" @submit.native.prevent="auth()">
          <p class="text_32 text_b text_dark mb_24">Войти</p>

          <el-form-item label="Логин">
            <el-input v-model="login.email" placeholder="Email" clearable/>
          </el-form-item>
          <el-form-item label="Пароль">
            <el-input v-model="login.password" placeholder="Введите пароль" type="password" clearable/>

            <p v-if="authData.error" class="text_13 text_red">
              Неверный логин или пароль
            </p>

            <el-button :loading="authData.loading" :disabled="authData.loading" class="mt_24" type="primary" native-type="submit">
              Войти
            </el-button>
          </el-form-item>

          <p v-if="false" class="text_15 text_blue text_b mb_14 text_ac cp" @click="authAction = 'reg'">Зарегистрироваться</p>

          <p v-if="false" class="text_15 text_blue text_b text_ac cp">Получить демо доступ</p>
        </el-form>

        <el-form v-else :label-position="'top'" label-width="100px" @submit.native.prevent="registration()">
          <p class="text_15 text_blue text_b mb_4 cp" @click="authAction = 'login'">Войти</p>

          <p class="text_32 text_b text_dark mb_24">Регистрация</p>

          <el-form-item label="Email">
            <el-input v-model="reg.email" placeholder="Введите вашу почту" clearable/>
          </el-form-item>
          <el-form-item :class="{'is-error': errors.reg}" style="margin-bottom: 4px" label="Пароль">
            <el-input v-model="reg.pass1" placeholder="Придумайте пароль" clearable/>
          </el-form-item>

          <el-form-item :class="{'is-error': errors.reg}">
            <el-input v-model="reg.pass2" placeholder="Повторите пароль" clearable/>

            <p v-if="errors.reg" class="text_13 text_red mt_4">Пароли не совпадают</p>
          </el-form-item>

          <el-button class="mb_20" type="primary" native-type="submit">Зарегистрироваться</el-button>
        </el-form>
      </div>
    </div>

    <div class="login-img" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { AUTH_TOKEN_REQUEST } from '@/store/actions/auth';

export default {
  name: 'Login',
  data() {
    return {
      login: {
        email: '',
        password: '',
      },
      reg: {
        email: '',
        pass1: '',
        pass2: '',
      },
      errors: {
        reg: false,
      },
      authAction: 'login',
    };
  },
  computed: {
    ...mapGetters(['authData']),
  },
  methods: {
    auth() {
      this.$store.dispatch(AUTH_TOKEN_REQUEST, this.login).then(() => {
        console.log('auth');
        this.$router.push('/');
      });
    },
    registration() {
      if (this.reg.email.length) {
        if (this.reg.pass1.length && this.reg.pass2.length && this.reg.pass1 === this.reg.pass2) {
          this.errors.reg = false;
          console.log('reg');
        } else {
          this.errors.reg = true;
        }
      }
    },
  },
};
</script>

<style scoped>

</style>
